export enum Colors {
  Navy = "#1b2230",
  BlueSmoke = "#222a3a",
  X3 = "#283041",
  Slate = "#3d4351",
  MarbleBlue = "#5f6674",
  Sot = "#8f96a2",
  X1 = "#a3aab4",
  X2 = "#dde4ef",
  Hearty = "#ea7884",
  Sky = "#eef2f8",
  Cream = "#f2ecdd",
  ShinyGold = "#f4b16b",
  Gold = "#f5d47b",
  Milk = "#f9f6ee",
  MatteGold = "#ffefc4",
  White = "#ffffff",
}
